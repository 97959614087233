// VARIABLES
$mainFont: 'Poppins', sans-serif;
$arabicFont: 'Noto Kufi Arabic', sans-serif;
$lineHeight: 1.8;
$mainFontSize: 16px;

// DESIGN COLORS
$primaryColor: #7C30d8;
$secondryColor: #FEB95A;
$greenColor: #3EA917;
$greyColor: #D1D1D9;
$whiteColor: #FFFFFF;
$blackColor: #232323;