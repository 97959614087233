#portfolio {
  position: relative;
  overflow: hidden;

  .tabs {
    .filter-btn {
      a {
        color: $primaryColor;

        span {
          text-transform: capitalize;
        }
      }
    }
  }

  .portfolio-items {
    position: sticky;
    z-index: 3;

    .portfolio-item {
      border-radius: 0.75rem;

      &:hover .type {
        animation: topdown 1s forwards;
      }

      @keyframes topdown {
        0% {
          top: 80%;
          opacity: 0;
        }

        25% {
          top: 120%;
          opacity: 0;
        }

        50% {
          top: -20%;
          opacity: 0;
        }

        75% {
          opacity: 0;
        }

        100% {
          opacity: 1;
          top: 10%;
          gap: 10px;
        }
      }

      .card-header {
        box-shadow: none;
        position: relative;
        overflow: hidden;
        padding: 0;

        img {
          border-radius: 0.75rem 0.75rem 0 0;
          transition: all 1s ease-in-out;
        }

        // &:hover img {
        //   filter: grayscale(1);
        // }

        .type {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          position: absolute;
          top: 80%;
          left: 20px;
          transition: all 0.3s ease-in;

          .cat {
            padding: 0px 22px;
            background-color: #9f5cf1;
            border-radius: 11px;

            span {
              color: #fff;
              text-transform: capitalize;
            }
          }
        }
      }

      .card-footer {
        padding: 20px;

        .is-flex {
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        h3 {
          font-weight: 700;
          font-size: 1.1rem;
          color: $primaryColor;
        }

        a {
          color: $primaryColor;
          font-weight: 400;
          display: flex;
          align-items: center;

          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    width: 550px;
    height: 550px;
    background-color: rgba(254, 185, 90, 0.25);
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: -11%;
    bottom: -28%;
  }

  .portfolio-title {
    position: relative;
    margin-top: 80px;
    right: 0;
    z-index: 3;

    h2 {
      font-weight: 900 !important;
      color: $primaryColor;
      font-size: 1.75rem;
      text-align: right;
    }
  }

  .portfolio-draw-image {
    max-width: 499px;
    position: absolute;
    bottom: 0;
    left: -50px;
    opacity: 0.7;
    z-index: 2;
  }
}