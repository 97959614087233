.services {
    padding-top: 30px;
}

.services .card {
    width: 100%;
    height: 100%;
    padding: 2em .5em;
    background: linear-gradient(#ffffff 50%, $primaryColor 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
}

.services h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
}

.services p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
}

.services .icon-wrapper {
    background-color: $primaryColor;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.services .card:hover {
    background-position: 0 100%;
}

.services .card:hover .icon-wrapper {
    background-color: #ffffff;
    color: $primaryColor;
}

.services .card:hover h3 {
    color: #ffffff;
}

.services .card:hover p {
    color: #f0f0f0;
}

@media (max-width: 768px) {
    html body section {
        padding-left: 0 !important;
    }
}